import './App.css';
import {Routes,Route,BrowserRouter,Navigate} from "react-router-dom";
import Header from './components/common/header/header';

import D6_SL_Footer from './components/common/footer/D6_SL_Footer';
import { useState,useEffect } from 'react';
import BlogsSub from './components/blogs/blogsSub';
import BlogsMain from './components/blogs/blogsMain';
import HeaderB from './components/common/header/headerB';
import Dec6_ScrollNPT from './components/common/STTP/scroll_Top';
import ContactUs from './components/contact_us/contact_us';
import TBI_PrivacyPolicy from './components/common/privacypolicy/TBI_PrivacyPolicy';
import Search from './components/search/search';
// export const apilink='http://127.0.0.1:8000/'
// export const apilink='https://filly-prelive.herokuapp.com/'
export const apilink='https://filly-sites.herokuapp.com/'

var val=window.location.pathname
  
var page=val.replace('/','')

function App() {
  const [tagData,setTagData]=useState([])
  const [blogData,setBlogData]=useState([])
  const [adv,setADVData]=useState([])


  const [reload,setReload]=useState(0)
  
const reloadFxn=()=>{
 setReload(reload+1)
}

  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'fm-tags-data2',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setTagData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])
  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'fm-blog-data2',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setBlogData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])
  useEffect(() => {
    async function fetchApi(){
      fetch(apilink+'fm-add-data?q=FM_Blogs2',
      { method: 'GET',
      headers:{
        'Content-Type' :'application/json'  
      },})
      .then(res => res.json())
      .then(resp=>setADVData(resp))
      .catch(err=>console.log(err))


    }
    fetchApi()
  }, [reload])
  if(val==='/'||val==='/contactus'||val==='/privacypolicy'||val.includes('search')){
    return(<>
 
<BrowserRouter> 
      <Dec6_ScrollNPT />
    {/* {blogData&&blogData[0]&&tagData&&tagData[0]&&adv&&adv[0]?<Header/>:null} */}
    <Header/>
    <Routes>
                        <Route  path="/" element={<BlogsMain blogData={blogData} tagData={tagData} adv={adv}/>} /> 
                        <Route  path="/contactus" element={<ContactUs   />} /> 
                        <Route  path="/privacypolicy" element={<TBI_PrivacyPolicy   />} /> 
                        <Route  path="/search" element={<Search   />} /> 
 
<Route path="*" element={<Navigate to ="/" />}/>
         </Routes>
        {window.location.pathname!=='/fm-gate'&&blogData&&blogData[0]&&tagData&&tagData[0]?<>
         <D6_SL_Footer /></> :null}
 
          </BrowserRouter>
 
      </>
    )
  }
  else{
  return (
    <BrowserRouter> 
    <Dec6_ScrollNPT />
      <HeaderB/>
       <BlogsSub page={page} reloadFxn={reloadFxn} tagData={tagData} adv={adv} blogData={blogData}/>
      { blogData&&blogData[0]&&tagData&&tagData[0]&&adv&&adv[0]?  <D6_SL_Footer />:null}
          </BrowserRouter>
  
  );}
}

export default App;
